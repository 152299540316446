import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '1000px',
      padding: '2rem',
      margin: '5rem auto 15rem auto'
    }}>
      <Title mdxType="Title">Privacy Policy</Title>
      <p><strong parentName="p">{`Last Updated: August 2nd, 2022`}</strong></p>
      <p>{`Welcome to `}<a parentName="p" {...{
          "href": "http://www.nerveglobal.com"
        }}>{`www.nerveglobal.com`}</a>{` (the “Nerve Site”, “NERVE Site”). NERVE CORP (NERVE, “Nerve”, “NRV-G”, “we”, “us” or “our”) is committed to treating the personal and corporate information of our
users with respect and sensitivity. Our privacy commitment is described in this privacy policy (the “Privacy Policy”). NERVE CORP provides the Nerve Site to you subject
to your acceptance of the Privacy Policy.`}</p>
      <p>{`NERVE CORP is a group of researchers, developers and technology professionals who are passionate about the potential of decentralized applications.
NERVE CORP operates the `}<a parentName="p" {...{
          "href": "http://www.nerveglobal.com"
        }}>{`www.nerveglobal.com`}</a>{` domain name and various social media accounts. it does not own or lead Nerve Global (“NERVE”, “Nerve”), but rather supports and develops the free, open-source application that is Nerve.
NERVE CORP makes no money from these web properties.`}</p>
      <p>{`NERVE CORP has not any role in the operation of dares created on Nerve Global, and do not has the ability to spend funds that are
held in escrow on-contract, do not control how dares resolve or are created, do not approve or reject those or other transactions on the network, and do not
has the ability to modify, cancel, undo, or interact with transactions on the network. NERVE CORP has no power to censor, restrict, or curate
transactions or resolutions on the Nerve Global contracts. NERVE CORP has no more control over the Nerve application than anyone else using Ethereum, Polygon or any other EVM based blockchain.`}</p>
      <h1 {...{
        "id": "links-to-other-websites",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#links-to-other-websites",
          "aria-label": "links to other websites permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Links to Other Websites`}</h1>
      <p>{`Our service may contain links to other websites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the privacy policy of every site you visit.
We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.`}</p>
      <h1 {...{
        "id": "changes-to-this-privacy-policy",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#changes-to-this-privacy-policy",
          "aria-label": "changes to this privacy policy permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Changes to this privacy policy`}</h1>
      <p>{`We may change this privacy policy. If we make any changes, we will change the Last Updated date above.`}</p>
      <p>{`We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
If we make any changes, we will change the “Last Updated” date above. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.`}</p>
      <h1 {...{
        "id": "contact-information",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#contact-information",
          "aria-label": "contact information permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Contact information`}</h1>
      <p>{`If you have any questions about this privacy policy, you can contact us:`}</p>
      <p><a parentName="p" {...{
          "href": "mailto:business@nerveglobal.com"
        }}>{`business@nerveglobal.com`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      